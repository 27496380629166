import React, { useEffect } from 'react';
import Layout from 'components/layout';
import { navigate } from '@reach/router';

function NotFound() {
  useEffect(() => {
    navigate('/');
  }, []);
  return <Layout></Layout>;
}

export default NotFound;
